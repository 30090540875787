import { Application } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

// Import Bootstrap JS
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
// Import our SCSS
import "../garage/styles/garage.scss"

// Initialize Stimulus
const application = Application.start()

// Register controllers
import ThemeController from "../shared/controllers/theme_controller"
import ResendConfirmationController from "../shared/controllers/resend_confirmation_controller"
import SignupFormController from "../shared/controllers/signup_form_controller"
import CountdownController from "../shared/controllers/countdown_controller"

application.register("theme", ThemeController)
application.register("resend-confirmation", ResendConfirmationController)
application.register("signup-form", SignupFormController)
application.register("countdown", CountdownController)

// Create a new controller for password validation
class PasswordFormController {
  connect() {
    this.initPasswordValidation()
  }

  initPasswordValidation() {
    const form = document.querySelector('#password_form form')
    if (!form) return

    const currentPassword = document.getElementById('current_password')
    const newPassword = document.getElementById('new_password')
    const passwordConfirmation = document.getElementById('password_confirmation')
    const errorContainer = document.getElementById('password-form-errors')

    if (!currentPassword || !newPassword || !passwordConfirmation) return

    // Add real-time validation for password matching
    passwordConfirmation.addEventListener('input', () => {
      if (newPassword.value && newPassword.value !== passwordConfirmation.value) {
        passwordConfirmation.classList.add('is-invalid')
      } else {
        passwordConfirmation.classList.remove('is-invalid')
      }
    })

    // Also check when password changes
    newPassword.addEventListener('input', () => {
      // Check length
      if (newPassword.value && newPassword.value.length < 6) {
        newPassword.classList.add('is-invalid')
      } else {
        newPassword.classList.remove('is-invalid')
      }

      // Check match with confirmation
      if (passwordConfirmation.value && newPassword.value !== passwordConfirmation.value) {
        passwordConfirmation.classList.add('is-invalid')
      } else {
        passwordConfirmation.classList.remove('is-invalid')
      }
    })

    // Add form submission validation
    form.addEventListener('submit', (event) => {
      let isValid = true
      let errors = []

      // Reset validation states
      if (errorContainer) {
        errorContainer.style.display = 'none'
        errorContainer.innerHTML = ''
      }

      currentPassword.classList.remove('is-invalid')
      newPassword.classList.remove('is-invalid')
      passwordConfirmation.classList.remove('is-invalid')

      // Check current password
      if (!currentPassword.value.trim()) {
        currentPassword.classList.add('is-invalid')
        errors.push("Current password can't be blank")
        isValid = false
      }

      // Check new password length
      if (newPassword.value && newPassword.value.length < 6) {
        newPassword.classList.add('is-invalid')
        errors.push("New password is too short (minimum is 6 characters)")
        isValid = false
      }

      // Check passwords match
      if (newPassword.value !== passwordConfirmation.value) {
        passwordConfirmation.classList.add('is-invalid')
        errors.push("Password confirmation doesn't match new password")
        isValid = false
      }

      if (!isValid && errorContainer) {
        event.preventDefault()
        errorContainer.style.display = 'block'

        // Display all errors
        const ul = document.createElement('ul')
        errors.forEach(error => {
          const li = document.createElement('li')
          li.textContent = error
          ul.appendChild(li)
        })
        errorContainer.appendChild(ul)
      }
    })
  }
}

// Register the password controller
application.register("password-form", PasswordFormController)

// Initialize password validation on page load
document.addEventListener("turbo:load", () => {
  const passwordForm = document.getElementById('password_form')
  if (passwordForm) {
    const controller = new PasswordFormController()
    controller.connect()
  }
})

// Prevent navigation to other pages
document.addEventListener("turbo:before-visit", (event) => {
  if (!event.detail.url.includes('/v3/')) {
    event.preventDefault()
    window.location.href = event.detail.url
  }
})

// Start Turbo
Turbo.start()
